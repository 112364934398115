import React from "react";
import classNames from "classnames";
import useDefaultEvents from "@staticQueries/DefaultEventsQuery";
import useLang from "@hooks/useLang";
import { Carousel, EventSlide } from "@molecules";
import { Text, AnimateIn } from "@atoms";

const EventCarousel = ({
  heading,
  paddingTop,
  paddingBottom,
  events: _events,
}) => {
  const lang = useLang();
  const events = _events.length
    ? _events
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useDefaultEvents()[lang === "en" ? "en" : "es"];
  return (
    <section
      className={classNames("w-full overflow-hidden pb-20 pt-20 md:pb-28", {
        "md:pt-28": paddingTop,
        // "md:pb-28": paddingBottom,
      })}
    >
      <div className="relative mx-auto max-w-8xl px-4 sm:px-6 md:px-0">
        <div className="flex flex-wrap items-end gap-12 md:flex-nowrap md:gap-20 md:pr-16 lg:gap-36 lg:pr-32">
          <div className="relative z-10 -translate-x-0 md:-translate-x-[.55rem] md:[writing-mode:vertical-rl]">
            <AnimateIn preset="fadeUpFast" threshold="some">
              <Text variant="heading" className="max-h-[10ch] md:rotate-180">
                {heading}
              </Text>
            </AnimateIn>
          </div>
          {events?.length > 1 ? (
            <Carousel maxVisible={1} indicators className="-mx-4">
              {events.map(event => {
                return (
                  <div key={event.uid} className="h-full px-4">
                    <EventSlide {...event} />
                  </div>
                );
              })}
            </Carousel>
          ) : (
            <AnimateIn
              key={events[0]?.uid}
              preset="fadeUpFast"
              threshold="some"
              delay={0.2}
            >
              <EventSlide {...events[0]} />
            </AnimateIn>
          )}
        </div>
      </div>
    </section>
  );
};

EventCarousel.defaultProps = {};

export default EventCarousel;
