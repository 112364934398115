import { useStaticQuery, graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";

const query = graphql`
  query DefaultEventsQuery {
    craft {
      en: entries(section: "events", limit: 6) {
        ... on Craft_events_default_Entry {
          id
          uid
          heading: title
          descriptor: shortDescriptor0
          date: date0
          button: link0 {
            text
            url
          }
          image: metaImage {
            ...ImageFragment
          }
        }
      }
      es: entries(
        section: "events"
        site: "artsCouncilSccSpanishEs"
        limit: 6
      ) {
        ... on Craft_events_default_Entry {
          id
          uid
          heading: title
          descriptor: shortDescriptor0
          date: date0
          button: link0 {
            text
            url
          }
          image: metaImage {
            ...ImageFragment
          }
        }
      }
    }
  }
`;

const dataResolver = entries => {
  const events = entries?.map(event => {
    return {
      ...event,
      image: resolveImage(event.image),
    };
  });

  return events;
};

const useData = () => {
  const { craft } = useStaticQuery(query);
  const { en, es } = craft;
  return {
    en: dataResolver(en),
    es: dataResolver(es),
  };
};

export default useData;
